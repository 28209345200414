<template>
  <v-container fluid>
    <v-row>
      <v-col class="tw-px-0">
        <Top :info="info" />
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isAdd ? 8: 12" class="tw-px-0">
        <McTable
          :isHideMenu="false"
          :isHideAdd="false"
          :isHideSettings="false"
          :isLoading="isFetching"
          :search="search"
          :tabs="false"
          :headers="headers"
          :items="newItems"
          :footerProps="footerProps"
          :paging="paging"
          :pagingOptions="pagingOptions"
          :showExpand="false"
          :isTuneIcon="true"
          :itemsAction="true"
          :onAdd="doToggleAdd"
        >
        </McTable>
      </v-col>
      <v-col v-if="isAdd">
        <v-card
          elevation="2"
          rounded="xl"
        >
          <SearchUser
            :userSearch="userSearch"
            :items="customers"
            :doSearchUser="doSearchUser"
            :doUpdateData="doUpdateData"  
            :isLoading="isCustomerSearch || isProcessing"
            :doAddToQueue="doAddToQueue"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

// Utilities
import {
  cloneDeep,
  // isEqual,
  // pick,
  // get
} from 'lodash'

import {
  mapState,
  // mapActions,
  // mapMutations
} from 'vuex'
import {
  UPDATE_STORE_STATE,
  REQUEST_STORE_LIST,
  REQUEST_CUSTOMER_LIST,
  REQUEST_ADD_USER_QUEUE,
  REQUEST_UPDATE_USER_STATUS,
// RESET_PRODUCT_STATE,
} from '@/store/modules/techbar'
import { initComponent } from '@/lib'


export default initComponent ('TechBarQueue', {
  isPage: true,
  components : {
    // Chart1: () => import('./components/widgets/ChartOne'),
    McTable: () => import('@/components/base//Table'),
    Top: () => import('./components/Top.vue'),
    SearchUser: () => import('./components/SearchUser.vue'),

  },
  data () {
    return {
      headers: [
        {
          text: 'Customer Name',
          align: 'start',
          class: '',
          sortable: true,
          width: '20%',
          value: 'customer_name',
        },
        { text: 'Status', value: 'status', align: 'center'},
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
          width: '30%',
        },
      ],
      recent: [
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          name: 'Jason Oner',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          name: 'Mike Carlson',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          name: 'Cindy Baker',
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          name: 'Ali Connors',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isCustomerSearch: (state) => state.techbar.isCustomerSearch,
      isFetching: (state) => state.techbar.isFetching,
      isProcessing: (state) => state.techbar.isProcessing,
      items: (state) => state.techbar.items,
      paging: (state) => state.techbar.paging,
      item: (state) => state.techbar.item,
      search: (state) => state.techbar.search,
      userSearch: (state) => state.techbar.userSearch,
      isAdd: (state) => state.techbar.isAdd,
      customers: (state) => state.techbar.customers,
      customer: (state) => state.techbar.customer,
      customerPaaging: (state) => state.techbar.customerPaaging,
      info: (state) => state.techbar.info,
    }),
    newItems: function () { //add call to actioon array for assist and remove
      let newItems = [];
      this.items.map(item => {
        if (item.status === 'requested') {
          newItems.push({
            ...item,
            actions: [
              {
                label: 'Assist',
                action: this.doAssist,
                class: 'tw-text-success'
              },
              {
                label: 'Remove',
                action: this.doRemove,
                class: 'tw-text-error'
              }
            ],
          });
          return;
        }
        newItems.push({
          ...item,
          actions: [
            {
              label: 'Remove',
              action: this.doRemove,
              class: 'tw-text-error'
            }
          ],
        });
      });

      return newItems;
    },
    pagingOptions() {
      let option = {
        state: 'techbar',
        commit: UPDATE_STORE_STATE,
        dispatch: REQUEST_STORE_LIST,
      }
      return option;
    }
  },
  methods: {
    async onReady () {
      // return this.$store.commit(UPDATE_STORE_STATE)
      const { dispatch } = this.$store
      dispatch(REQUEST_STORE_LIST);

      window.setInterval(() => {
        dispatch(REQUEST_STORE_LIST);
      }, 60000);
    },
    doUpdateData (value, key) {
      const { commit, state } = this.$store
      let userSearch = cloneDeep(state.techbar.userSearch)
      userSearch = value
      return commit(UPDATE_STORE_STATE, { [`${key}`] : userSearch })
    },
    doToggleAdd () {
      const { commit } = this.$store;
      commit(UPDATE_STORE_STATE, { isAdd : !this.isAdd} );
    },
    doSearchUser () {
      const { dispatch, commit } = this.$store;
      commit(UPDATE_STORE_STATE, { customer : {} });
      dispatch(REQUEST_CUSTOMER_LIST);
    },
    async doAddToQueue ( item ) {
      const { commit, dispatch} = this.$store;
      await commit(UPDATE_STORE_STATE, { customer : item });
      await dispatch(REQUEST_ADD_USER_QUEUE);
    },
    async doAssist ( item ) {
      let status = 'response'
      const { commit, dispatch, state } = this.$store;
      const { app } = state;
      await commit(UPDATE_STORE_STATE, { item : {...item, status : status, admin: {...app.admin}} });
      await dispatch(REQUEST_UPDATE_USER_STATUS);
    },
    async doRemove ( item ) {
      let status = 'complete'
      const { commit, dispatch, state } = this.$store;
      const { app } = state;
      await commit(UPDATE_STORE_STATE, { item : {...item, status : status, admin: {...app.admin}} });
      await dispatch(REQUEST_UPDATE_USER_STATUS);
    },
    
  }
})
</script>

<style lang="sass" scoped>

</style>